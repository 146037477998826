import * as React from "react"
import { Link } from "react-router-dom"
import { Hero } from "@allied/react-web/Hero"
import { Meta } from "@allied/react-web/Meta"
import { Image } from "@allied/react-web/Image"
import { Loader } from "@allied/react-web/Loader"
import { useAutoPosition, useHashFragment } from "@allied/react-web/hooks"
import { DepotLocation } from "../components/Depot/DepotLocation"
import alliedHero from "../assets/image/allied-hero.webp"
import alliedAbout from "../assets/image/about-us.webp"
import alliedVisionMission from "../assets/image/vision-mission.webp"
import alliedService1 from "../assets/image/service-1.webp"
import alliedService2 from "../assets/image/service-2.webp"
import alliedService3 from "../assets/image/service-3.webp"
import alliedService4 from "../assets/image/service-4.webp"
import alliedRegional1 from "../assets/image/regional-1.webp"
import alliedRegional2 from "../assets/image/regional-2.webp"
import alliedRegional3 from "../assets/image/regional-3.webp"
import alliedRegional4 from "../assets/image/regional-4.webp"
import alliedAward1 from "../assets/image/award-1.webp"
import alliedAward2 from "../assets/image/award-2.webp"
import alliedAward3 from "../assets/image/award-3.webp"
import alliedAward4 from "../assets/image/award-4.webp"

const services = [
  {
    image: alliedService1,
    title: "Container Transportation",
    content: (
      <>
        <p>
          Allied Container Group’s principal business, ALLIED CONTAINER SERVICES PTE. LTD. is Singapore’s premier container haulier.
        </p>
        <p>
          With a large fleet of approximately 60 prime movers and 300 chassis, the company undertakes all types of land transportation in Singapore, handling over-height and over-width uncontainerised cargo, loose cargo, local delivery and transhipments etc.
        </p>
      </>
    )
  },
  {
    image: alliedService2,
    title: "Container Depot",
    content: (
      <>
        <p>
          ALLIED CONTAINER (ENGINEERS & MANUFACTURERS) PTE. LTD. provide depot services such as container storage, pre-trip inspection as well as washing and repair of containers, where the total depot capacity can hold up to 12,000 TEUs.
        </p>
        <p>
          We utilize a variety of modern equipment that are able to handle laden and empty containers.
        </p>
      </>
    )
  },
  {
    image: alliedService3,
    title: "Warehousing & Distribution",
    content: (
      <>
        <p>
          WINSPEC LOGISTICS SERVICES PTE. LTD. provides warehousing facilities in Tuas and Pioneer area. We provide 3rd party logistics (3PL) service to our customers.
        </p>
        <p>
          Our warehouse services include: Inventory and Stock Management, Distribution, Port Clearance, Trade documentation, Freight, Stuffing and Unstuffing, Picking and Packing, Palletising and unpalletising, labelling and stencilling.
        </p>
        <p>
          The LCL cargoes are consolidated and deconsolidated using 3-ton and 4-ton forklifts for overseas shipment and local distribution.
        </p>
      </>
    )
  },
  {
    image: alliedService4,
    title: "Software",
    content: (
      <>
        <p>
          Our IT Team provides hardware and software support within the Allied Group of Companies.
        </p>
        <p>
          Currently, we have our own Depot System called the Interactive Depot Operating System (iDOS) that facilitates the GateIn and GateOut of the containers.
        </p>
        <p>
          Customers can look forward to further system developments that our IT team is currently developing.
        </p>
      </>
    )
  }
]

const regionals = [
  {
    image: alliedRegional1,
    title: "MPA-ALLIED YANGON INLAND CONTAINER DEPOT LTD.",
    content: (
      <>
        <p>
          Established in 1996 as a joint venture investment with the Myanmar Port Authority (MPA), we are the first inland container depot in Yangon or known as ICD 1. The company operates on 10 acres of container depot with a fleet of modern equipment that is able to handle both empty and laden containers. The depot have the capacity to hold up to 5,200 TEUs.
        </p>
      </>
    )
  },
  {
    image: alliedRegional2,
    title: "ALLIED CONTAINER DEPOT SERVICES SDN. BHD.",
    content: (
      <>
        <p>
          Established in 1999 initially as a depot operator, we were the first to start the rail operation between Singapore and Malaysia. The company is now the rail operating land feeder within Malaysia and landbridge operator between Malaysia and South Thailand. We have also restarted our Malaysia depot in Port Klang in 2020.
        </p>
      </>
    )
  },
  {
    image: alliedRegional3,
    title: "ALLIED ONDOCK CONTAINER SERVICES LLC.",
    content: (
      <>
        <p>
          Allied Ondock Container Services LLC is established in 2014 as a joint venture investment. It is currently the one and only ondock operator in the Khalifa Port of Abu Dhabi. The company operates on 2 acres of container depot with a fleet of modern equipment, allowing it to handle up to 1,000 TEUs per day. The company provide container inspection, repair, pre-trip inspection, and washing facilities. Operations are computerised, leading to a high level of efficiency and reliability in the monitoring of container movements in and out, as well as inventory reporting, documentation and logistics support.
        </p>
      </>
    )
  },
  {
    image: alliedRegional4,
    title: "WINSPEC LOGISTICS (THAILAND) CO. LTD.",
    content: (
      <>
        <p>
          Established in 2017 but started business officially in 2018. We are the authorized State Railway of Thailand (SRT) operator and authorized by KTMB as Landbridge operator. The company work with the SRT to provide rail terminal operation between South Thailand and Malaysia. We are able to carry cargoes as well as containers between the two countries and linking Singapore by road.
        </p>
      </>
    )
  },
]

const awards = [
  {
    image: alliedAward1,
    title: "Radin Mas Community Club Appreciation",
  },
  {
    image: alliedAward2,
    title: "Sarjak Appreciation",
  },
  {
    image: alliedAward3,
    title: "KNS 5-Years Without Lost Time Injury",
  },
  {
    image: alliedAward4,
    title: "Compliments from MPA",
  }
]

const depotLocations = [
  {
    name: "ALLIED DEPOT 2",
    street_name: "14 Pioneer Sector 2, Singapore 628375",
    contact_name: "Mr Eddie",
    contacts: [
      {
        type: "email",
        label: "Email",
        value: "eddie@allied.com.sg",
      },
      {
        type: "tel",
        label: "DM Tel",
        value: "+65 6238 4862",
      },
      {
        type: "tel",
        label: "IN COUNTER Tel",
        value: "+65 6238 4874",
      },
      {
        type: "tel",
        label: "OUT COUNTER Tel",
        value: "+65 6238 4874",
      },
      {
        type: "tel",
        label: "RELEASE COUNTER Tel",
        value: "+65 6238 4874",
      }
    ],
  },
  {
    name: "ALLIED DEPOT 3",
    street_name: "No. 25 Penjuru Lane, Singapore 609194",
    contact_name: "Mr Parthiban",
    contacts: [
      {
        type: "email",
        label: "Email",
        value: "parthiban@allied.com.sg",
      },
      {
        type: "tel",
        label: "DM Tel",
        value: "+65 6867 9246",
      },
      {
        type: "tel",
        label: "IN COUNTER Tel",
        value: "+65 6867 9248",
      },
      {
        type: "tel",
        label: "OUT COUNTER Tel",
        value: "+65 6867 9241",
      },
      {
        type: "tel",
        label: "RELEASE COUNTER Tel",
        value: "+65 6867 9240",
      }
    ],
  },
  {
    name: "ALLIED DEPOT 5",
    street_name: "18A Penjuru Walk Singapore 609150",
    contact_name: "Mr Vincent Phua",
    contacts: [
      {
        type: "email",
        label: "Email",
        value: "vincent@allied.com.sg",
      },
      {
        type: "tel",
        label: "DM Mobile",
        value: "+65 9159 4283",
      },
      {
        type: "tel",
        label: "IN COUNTER Tel",
        value: "+65 6261 5816",
      },
      {
        type: "tel",
        label: "OUT COUNTER Tel",
        value: "+65 6261 5819",
      },
      {
        type: "tel",
        label: "RELEASE COUNTER Tel",
        value: "+65 6261 5817",
      }
    ],
  },
  {
    name: "ALLIED DEPOT 6",
    street_name: "1, Banyan Place, Jurong Island Singapore 627841",
    contact_name: "Mr KC Teo",
    contacts: [
      {
        type: "email",
        label: "Email",
        value: "acsji@allied.com.sg",
      },
      {
        type: "tel",
        label: "DM Tel",
        value: "+65 6419 2180",
      },
      {
        type: "tel",
        label: "DM Mobile",
        value: "+65 8154 5012",
      },
    ],
  },
  {
    name: "ALLIED DEPOT 8",
    street_name: "No. 15 Pioneer Crescent Singapore 628552",
    contact_name: "Mr Dannie Tan",
    contacts: [
      {
        type: "email",
        label: "Email",
        value: "dannie@allied.com.sg",
      },
      {
        type: "tel",
        label: "DM Tel",
        value: "+65 6513 4535",
      },
      {
        type: "tel",
        label: "DM Tel",
        value: "+65 6513 4542",
      },
      {
        type: "tel",
        label: "IN COUNTER Tel",
        value: "+65 6513 4534",
      },
      {
        type: "tel",
        label: "OUT COUNTER Tel",
        value: "+65 6513 4563",
      },
      {
        type: "tel",
        label: "RELEASE COUNTER Tel",
        value: "+65 6513 4533",
      },
      {
        type: "tel",
        label: "RELEASE COUNTER Tel",
        value: "+65 6513 4536",
      }
    ],
  }
]

export default function Home(): React.JSX.Element {
  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta disableTemplate>
        {{
          title: "Allied Container Group - Yard Operator, Haulier Operator and 3PL Provider",
          description: "Official website for Allied Containers Services and Allied Container (Engineers and Manufacturers) - Allied Group, Singapore | Largest container yard operator and container haulier | Allied depot"
        }}
      </Meta>
      <Hero.AlliedContainer
        primaryAction="/#about_us"
        secondaryAction="/#services"
        backgroundImage={alliedHero} />
      <div id="about_us" className="scroll-my-20 px-3 lg:!px-24 3xl:!px-64 py-8 lg:!py-14">
        <div className="grid grid-cols-12 gap-3 py-8">
          <div className="col-span-12 lg:!col-span-6">
            <h1 className="py-4 text-3xl lg:!text-4xl text-center text-acem-red font-bold uppercase">
              About Us
            </h1>
            <div className="flex flex-col gap-2">
              <p>
                Established in 1975, Allied Container Group has grown to become one of Singapore’s largest container haulage operator and container yard operator. With over 40 years of experience, the Group has a strong reputation in the line of logistics management. Today, Allied have more than 200 dedicated and experienced staff delivering the highest standards of service.
              </p>
              <p>
                The Group has committed itself to invest in the most up-to-date equipment and system available to the industry. With an eye on the growing role I.T. is playing in the shipping industry, Allied is at the forefront of software development. We have our own team of software engineers to develop and maintain system connectivity with our customers.
              </p>
              <p>
                In 2006, we established <Link className="text-acem-red underline" to="https://www.winspecgroup.com/" target="_blank">Winspec Group</Link>, a sister company of Allied, that is a freight forwarding and logistics provider company. We offer warehouse and distribution services to our customers. We are also the sole distributor for ENOC marine lubricant.
              </p>
              <p>
                In 2017, we started <Link className="text-acem-red underline" to="https://alliedfood.sg/" target="_blank">Allied Food (S) Pte Ltd</Link>, where we trade and sell Japanese & Korean flour etc. We work with some of the most renowned flour manufacturers like Nippn and CJ Flour. Contact us via our Facebook now!
              </p>
            </div>
          </div>
          <div className="col-span-12 lg:!col-span-6 hidden lg:!flex justify-center items-center">
            <Image alt="about" src={alliedAbout} className="w-full h-full" lazy />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-3 py-8">
          <div className="col-span-12 md:!col-span-6">
            <div className="flex justify-center items-center">
              <Image alt="vision-mission" src={alliedVisionMission} className="w-fit h-fit" lazy />
            </div>
          </div>
          <div className="col-span-12 md:!col-span-6">
            <div className="flex flex-col gap-2">
              <h1 className="py-4 text-3xl lg:!text-4xl text-center text-acem-red font-bold uppercase">
                Vision
              </h1>
              <p className="text-center">
                "To be the most innovative container depot facility and logistics hub, so as to be the leading regional integrated logistics service provider in Singapore"
              </p>
              <h1 className="py-4 text-3xl lg:!text-4xl text-center text-acem-red font-bold uppercase">
                Mission
              </h1>
              <p className="text-center">
                "To deliver effective and high quality logistics using international standards facilities, technology and services"
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr className="mx-3 lg:!mx-24 3xl:!mx-64" />

      <div id="services" className="scroll-my-20 px-3 lg:!px-24 3xl:!px-64 py-8 lg:!py-14">
        <h1 className="py-4 text-3xl lg:!text-4xl text-center text-acem-red font-bold uppercase">
          Our Services
        </h1>
        <div className="grid grid-cols-12 gap-6 py-8">
          {
            services.map((service, i: number) => {
              return (
                <div key={`service-item-${i}`} className="col-span-12 md:!col-span-6">
                  <div className="flex justify-center items-center">
                    <Image alt={service.title} src={service.image} className="w-fit h-fit" lazy />
                  </div>
                  <h2 className="my-4 font-bold text-xl text-center uppercase">
                    {service.title}
                  </h2>
                  <div className="flex flex-col gap-2 text-center">
                    {service.content}
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      <hr className="mx-3 lg:!mx-24 3xl:!mx-64" />

      <div id="regional" className="scroll-my-20 px-3 lg:!px-24 3xl:!px-64 py-8 lg:!py-14">
        <h1 className="py-4 text-3xl lg:!text-4xl text-center text-acem-red font-bold uppercase">
          Regional
        </h1>
        {
          regionals.map((regional, i: number) => {
            return (
              <div key={`regional-item-${i}`} className="grid grid-cols-12 gap-4 py-4">
                <div className="col-span-12 md:!col-span-6">
                  <div className="flex justify-center items-center">
                    <Image alt={regional.title} src={regional.image} className="w-full h-full" lazy />
                  </div>
                </div>
                <div className={
                  "col-span-12 md:!col-span-6 " +
                  (i % 2 === 0 ? "order-last text-left md:text-right " : "order-last md:order-first text-left ") +
                  "flex flex-col justify-center gap-2"
                }>
                  <h2 className="my-4 font-bold text-xl uppercase">
                    {regional.title}
                  </h2>
                  <div className="flex flex-col gap-2">
                    {regional.content}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      <hr className="mx-3 lg:!mx-24 3xl:!mx-64" />
      <div id="depot_locations" className="scroll-my-20 px-3 lg:!px-24 3xl:!px-64 py-8 lg:!py-14">
        <h1 className="py-4 text-3xl lg:!text-4xl text-center text-acem-red font-bold uppercase">
          Depot Locations
        </h1>
        <div className="grid grid-cols-12 gap-6 py-8">
          <div className="col-span-12">
            <Loader lazy>
              <DepotLocation>
                {{
                  items: depotLocations
                }}
              </DepotLocation>
            </Loader>
          </div>
        </div>
      </div>

      <hr className="mx-3 lg:!mx-24 3xl:!mx-64" />
      <div id="awards" className="scroll-my-20 px-3 lg:!px-24 3xl:!px-64 py-8 lg:!py-14">
        <h1 className="py-4 text-3xl lg:!text-4xl text-center text-acem-red font-bold uppercase">
          Awards
        </h1>
        <div className="grid grid-cols-12 gap-6 py-8">
          {
            awards.map((award, i: number) => {
              return (
                <div key={`award-item-${i}`} className="col-span-12 md:!col-span-6">
                  <div className="flex justify-center items-center">
                    <Image alt={award.title} src={award.image} className="w-full h-full" lazy />
                  </div>
                  <h2 className="my-4 font-bold text-xl text-center">
                    {award.title}
                  </h2>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}
