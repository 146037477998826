import * as React from "react"
import { Alert, AlertVariant } from "@allied/react-web/Alert"
import { Button, ButtonVariant } from "@allied/react-web/Button"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { InputText, InputEmail, InputPhone } from "@allied/react-web/Input"
import { Link } from "@allied/react-web/Router"
import { Meta } from "@allied/react-web/Meta"
import { Modal, ModalSize } from "@allied/react-web/Modal"
import { Textarea } from "@allied/react-web/Textarea"
import { useAutoPosition, useValidator, Validator } from "@allied/react-web/hooks"
import { Contact as ContactService } from "../services/api/Contact"
import { ContactViewer } from "../components/Contact/ContactViewer"
import houseIcon from "../assets/icon/house.svg"
import phoneIcon from "../assets/icon/phone.svg"
import faxIcon from "../assets/icon/fax.svg"
import contactIcon from "../assets/icon/contact-us.svg"

const contactItems = [
  {
    name: "MR LIM KIAN CHIN",
    designation: "Managing Director",
    emails: ["kc_lim@allied.com.sg"],
    telephones: ["+65 6558 6079"],
  },
  {
    name: "MDM TEO PHAY GEK",
    designation: "Director",
    emails: ["pgteo@allied.com.sg"],
    telephones: ["+65 6558 6079"],
  },
  {
    name: "MR LIM KIAN CHUAN",
    designation: "Director",
    emails: ["lkchuan@allied.com.sg"],
  },
  {
    name: "MR FRANCIS TAN",
    designation: "General Manager, Depot",
    emails: ["francis@allied.com.sg"],
    telephones: ["+65 6558 6083"],
    mobiles: ["+65 9170 4742"],
  },
  {
    name: "MS PRIYANKA",
    designation: "Customer Service Manager, Depot",
    emails: ["priyanka@allied.com.sg"],
    telephones: ["+65 6980 8151"],
  },
  {
    name: "MR JING GAO",
    designation: "Manager, Transport",
    emails: ["jglim@allied.com.sg"],
    telephones: ["+65 6232 7570"],
  },
  {
    name: "MR LAVAN",
    designation: "Operation Manager, Transport (Container)",
    emails: ["lavan@allied.com.sg"],
    telephones: ["+65 6232 7580"],
  },
  {
    name: "MR FRANKIE SIM",
    designation: "Senior Operation Manager, Transport (Liquid)",
    emails: ["frankie@allied.com.sg"],
    telephones: ["+65 6232 7589"],
    mobiles: ["+65 8223 1016"],
  },
  {
    name: "MS ELAINE HAN",
    designation: "Customer Service Manager, Transport",
    emails: ["elaine@allied.com.sg"],
    mobiles: ["+65 9839 4373"],
  },
  {
    name: "MS JOLEAN LIM",
    designation: "HR Manager",
    emails: ["hr@allied.com.sg"],
    telephones: ["+65 6558 6049", "+65 6558 6048"],
    mobiles: ["+65 9836 3756"],
  },
  {
    name: "MR JAGWINDER SINGH",
    designation: "Senior Global Director",
    emails: ["jagwinder.singh@allied.com.sg"],
    telephones: [],
    mobiles: ["+65 9171 1040"],
  }
]

const contactService = new ContactService({
  host: process.env.REACT_APP_API_HOST
})

export default function Contact(): React.JSX.Element {
  useAutoPosition()

  const [modalOpened, setModalOpened] = React.useState(false)
  const [contactNotif, setContactNotif] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  })
  const [contactAlert, setContactAlert] = React.useState({
    header: <></>,
    content: <></>,
    variant: AlertVariant.DANGER,
    open: false,
    closeable: true,
  })
  const [contactForm, setContactForm] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    loading: false
  })
  const [contactValidator] = useValidator({
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().required().phone("SG").min(5).max(17).trim(),
    message: Validator.string().required().min(3),
  })

  const handleContactClick = () => {
    setModalOpened(true)
  }

  const handleContactSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setContactNotif({
      name: "",
      email: "",
      phone: "",
      message: "",
    })

    const validateRes = contactValidator.validate({
      name: contactForm.name,
      email: contactForm.email,
      phone: contactForm.phone,
      message: contactForm.message,
    })
    if (validateRes.error) {
      const err = validateRes.error

      if (err instanceof Validator.ValidationError) {
        setContactNotif((prevState) => {
          return {
            ...prevState,
            [err.path]: err.errors.join("\n")
          }
        })
      } else {
        console.warn("unexpected error: ", err)
      }

      return
    }

    setContactAlert((prevState) => {
      return {
        ...prevState,
        open: true,
        closeable: false,
        variant: AlertVariant.INFO,
        header: (
          <>Sending contact enquiry</>
        ),
        content: (
          <>
            <p className="italic">Please wait a moment...</p>
          </>
        )
      }
    })
    setContactForm((prevState) => {
      return {
        ...prevState,
        loading: true
      }
    })
    const sendRes = await contactService.SendEnquiry({
      ...validateRes.data
    })
    setContactForm((prevState) => {
      return {
        ...prevState,
        loading: false
      }
    })
    if (sendRes.error) {
      setContactAlert((prevState) => {
        return {
          ...prevState,
          open: true,
          closeable: true,
          variant: AlertVariant.DANGER,
          header: (
            <>Failed send enquiry</>
          ),
          content: (
            <>
              <p className="my-2 italic">
                Please try again later or contact us directly via phone or email
              </p>
              <p><span className="font-bold">Code:</span> {sendRes.error.code}</p>
              <p><span className="font-bold">Message:</span> {sendRes.error.message}</p>
            </>
          )
        }
      })
      return
    }

    setContactAlert((prevState) => {
      return {
        ...prevState,
        open: true,
        closeable: true,
        variant: AlertVariant.SUCCESS,
        header: (
          <>Success send enquiry</>
        ),
        content: (
          <>Your message is being sent</>
        )
      }
    })
    setContactForm((prevState) => {
      return {
        ...prevState,
        name: "",
        email: "",
        phone: "",
        message: "",
      }
    })
  }

  return (
    <>
      <Meta>
        {{
          title: "Contact Us",
          description: "Contact us for further enquiries."
        }}
      </Meta>
      <div className="py-10 md:!py-16 px-3 md:!px-24 3xl:!px-64">
        <div className="flex flex-col flex-wrap justify-center">
          <h1 className="text-center text-acem-red text-3xl md:!text-4xl font-bold uppercase py-2">
            Contact Us
          </h1>

          <img src={contactIcon} alt="contact us" className="block md:!hidden w-full h-full" />
        </div>
        <div className="flex flex-col flex-wrap justify-center">
          <div className="flex flex-col md:!flex-row flex-wrap justify-between items-center gap-10 md:gap-4 py-4">

            <div className="flex flex-1 flex-col justify-center items-center gap-4 md:gap-2">
              <img src={houseIcon} alt="address" className="w-8 h-8" />
              <p className="text-center text-acem-red">
                No 6 Tuas Avenue 6 Singapore 639311
                <br />
                No 10 Tuas Avenue 6 Singapore 639298
              </p>
            </div>
            <div className="flex flex-1 flex-col justify-center items-center gap-4 md:gap-2">
              <img src={phoneIcon} alt="address" className="w-8 h-8" />
              <Link to="https://api.whatsapp.com/send?phone=6565586079" target="_blank"
                className="mx-2 inline-block px-4 py-1 no-underline text-center text-acem-red"
                hideCrawl>
                +65 6558 6079
              </Link>
            </div>
            <div className="flex flex-1 flex-col justify-center items-center gap-4 md:gap-2">
              <img src={faxIcon} alt="address" className="w-8 h-8" />
              <Link to="tel:6568978952" target="_self"
                className="mx-2 inline-block px-4 py-1 no-underline text-center text-acem-red"
                hideCrawl>
                +65 6897 8952
              </Link>
            </div>
            <div className="flex flex-1 flex-col justify-center items-center gap-4 md:gap-2 md:order-last">
              <Button variant={ButtonVariant.PRIMARY} onClick={handleContactClick}>
                Contact the Team
              </Button>
            </div>
          </div>

          <div className="w-full flex items-center py-4">
            <div className="flex-grow h-px bg-gray-400"></div>
            <span className="flex-shrink text-acem-black px-12">or</span>
            <div className="flex-grow h-px bg-gray-400"></div>
          </div>

          <div className="grid grid-cols-12 py-4">
            <div className="col-span-12 md:!col-span-6">
              <img src={contactIcon} alt="contact us" className="hidden md:!block w-full h-full" />
            </div>
            <div className="col-span-12 md:!col-span-6">
              <h2 className="my-4 font-bold text-xl text-acem-red">
                Enquiry
              </h2>
              <Alert
                variant={contactAlert.variant}
                opened={contactAlert.open}
                closeable={contactAlert.closeable}
                onClose={() => {
                  setContactAlert((prevState) => {
                    return {
                      ...prevState,
                      open: false
                    }
                  })
                }}>
                {{
                  header: contactAlert.header,
                  content: contactAlert.content
                }}
              </Alert>
              <form method="POST" onSubmit={handleContactSubmit}>
                <div className="flex flex-col gap-3">
                  <Field>
                    <InputText name="name" placeholder="Name *"
                      value={contactForm.name}
                      onChange={(e) => {
                        setContactForm((prevState) => {
                          return {
                            ...prevState,
                            name: e.target.value,
                          }
                        })
                      }}
                      required disabled={contactForm.loading} />
                    <FieldNotif>{contactNotif.name}</FieldNotif>
                  </Field>
                  <Field>
                    <InputEmail name="email" placeholder="E-mail *"
                      value={contactForm.email}
                      onChange={(e) => {
                        setContactForm((prevState) => {
                          return {
                            ...prevState,
                            email: e.target.value,
                          }
                        })
                      }}
                      required disabled={contactForm.loading} />
                    <FieldNotif>{contactNotif.email}</FieldNotif>
                  </Field>
                  <Field>
                    <InputPhone name="phone" placeholder="Contact Number *"
                      value={contactForm.phone}
                      onUpdate={(m) => {
                        setContactForm((prevState) => {
                          return {
                            ...prevState,
                            phone: m.phone_number,
                          }
                        })
                      }}
                      required disabled={contactForm.loading} />
                    <FieldNotif>{contactNotif.phone}</FieldNotif>
                  </Field>
                  <Field>
                    <Textarea name="message" placeholder="Your Message *"
                      value={contactForm.message}
                      onChange={(e) => {
                        setContactForm((prevState) => {
                          return {
                            ...prevState,
                            message: e.target.value,
                          }
                        })
                      }}
                      required disabled={contactForm.loading} />
                    <FieldNotif>{contactNotif.message}</FieldNotif>
                  </Field>
                  <div>
                    <Button type="submit" variant="primary"
                      disabled={contactForm.loading}>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal portalId="overlay-root"
        size={ModalSize.FULL}
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false)
        }}
        closeable>
        {{
          header: (
            <>
              <div className="flex justify-center w-full">
                <h1 className="text-center text-acem-red text-3xl md:!text-4xl font-bold uppercase py-2">
                  Meet the Team {" "}
                  <span className="xs:block">(Singapore)</span>
                </h1>
              </div>
            </>
          ),
          content: (
            <div className="py-2 md:!py-4 px-4 md:!px-8 overflow-y-auto" style={{ height: "85vh" }}>
              <ContactViewer>
                {{
                  items: contactItems
                }}
              </ContactViewer>
            </div>
          )
        }}
      </Modal>
    </>
  )
}
